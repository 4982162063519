<template>
    <v-container fluid>
        <v-form @submit.prevent="saveCompany">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-subheader class="headline">{{ heading }}</v-subheader>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-tabs v-model="tab"
                                        background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4"
                                        @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2" class="mt-2 px-sm-6 text-center">
                                <make-logo v-model="logo" :aspect-ratio="1"/>
                            </v-col>
                            <v-col cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="8">
                                        <ValidationProvider
                                            ref="company"
                                            rules="required|min:1|max:255"
                                            v-slot="{ errors, valid }"
                                        >
                                            <v-text-field
                                                v-model="company"
                                                type="text"
                                                :error-messages="errors"
                                                :error="!valid"
                                                :disabled="loading"
                                                prepend-icon="mdi-rename-box"
                                                :label="$t('company_name')"
                                                color="primary"
                                                clearable
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="4">
                                        <ValidationProvider
                                            ref="name_director"
                                            rules="min:2|max:255"
                                            v-slot="{ errors, valid }"
                                        >
                                            <v-text-field
                                                v-model="name_director"
                                                type="text"
                                                :error-messages="errors"
                                                :disabled="loading"
                                                prepend-icon="mdi-account"
                                                :label="$t('name_director')"
                                                color="primary"
                                                clearable
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="8">
                                        <ValidationProvider
                                            ref="name_legal"
                                            rules="min:1|max:255"
                                            v-slot="{ errors, valid }"
                                        >
                                            <v-text-field
                                                v-model="name_legal"
                                                type="text"
                                                :error-messages="errors"
                                                :error="!valid"
                                                :disabled="loading"
                                                prepend-icon="mdi-rename-box"
                                                :label="
                                                    $t('company_name_legal')
                                                "
                                                color="primary"
                                                clearable
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="4">
                                        <ValidationProvider
                                            ref="name_responsible"
                                            rules="min:2|max:255"
                                            v-slot="{ errors, valid }"
                                        >
                                            <v-text-field
                                                v-model="name_responsible"
                                                type="text"
                                                :error-messages="errors"
                                                :disabled="loading"
                                                prepend-icon="mdi-account"
                                                :label="$t('name_responsible')"
                                                color="primary"
                                                clearable
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider
                                    ref="bin_iin"
                                    rules="required|numeric|length:12|bin_iin"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-text-field
                                        v-model="bin_iin"
                                        type="text"
                                        :error-messages="errors"
                                        :error="!valid"
                                        :disabled="loading"
                                        prepend-icon="mdi-numeric"
                                        :label="$t('bin_iin')"
                                        color="primary"
                                        clearable
                                        autocomplete="off"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider
                                    ref="phone"
                                    rules="phone"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-text-field
                                        v-model="phoneRaw"
                                        type="tel"
                                        v-mask="phoneMask"
                                        :error-messages="errors"
                                        :error="!valid"
                                        :disabled="loading"
                                        prepend-icon="mdi-phone"
                                        :label="$t('phone')"
                                        color="primary"
                                        clearable
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider
                                    ref="email"
                                    rules="email"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-text-field
                                        v-model="email"
                                        type="email"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        prepend-icon="mdi-email"
                                        label="Email"
                                        color="primary"
                                        clearable
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider
                                    ref="country"
                                    rules="required"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-autocomplete
                                        v-model="country"
                                        :items="countryItems"
                                        :error="!valid"
                                        :error-messages="errors"
                                        :search-input.sync="countrySearching"
                                        item-text="name"
                                        item-value="id"
                                        prepend-icon="mdi-web"
                                        :loading="loadingCountries"
                                        :disabled="loading"
                                        @click="clearCountries"
                                        :no-data-text="
                                            countrySearching
                                                ? $t('nothing_found_by', {
                                                      search: countrySearching,
                                                  })
                                                : $t(
                                                      'nothing_found_country_name'
                                                  )
                                        "
                                        :label="$t('country')"
                                        @click:clear="countryItems = []"
                                        color="primary"
                                        return-object
                                        clearable
                                        autocomplete="off"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider
                                    ref="city"
                                    rules="required"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-autocomplete
                                        v-model="city"
                                        :items="cityItems"
                                        :error="!valid"
                                        :error-messages="errors"
                                        :search-input.sync="citySearching"
                                        item-text="name"
                                        item-value="id"
                                        prepend-icon="mdi-city"
                                        :loading="loadingCities"
                                        :disabled="loading"
                                        @click="clearCities"
                                        :no-data-text="
                                            citySearching
                                                ? $t('nothing_found_by', {
                                                      search: citySearching,
                                                  })
                                                : $t('nothing_found_city_name')
                                        "
                                        :label="$t('city')"
                                        @click:clear="cityItems = []"
                                        color="primary"
                                        return-object
                                        clearable
                                        autocomplete="off"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider
                                    ref="contract_number"
                                    rules="required|min:1|max:255"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-text-field
                                        v-model="contract_number"
                                        type="text"
                                        :error-messages="errors"
                                        :error="!valid"
                                        :disabled="loading"
                                        prepend-icon="mdi-shield-star"
                                        :label="$t('contract_number')"
                                        color="primary"
                                        clearable
                                        autocomplete="off"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-dialog
                                    ref="dateDialogContract"
                                    v-model="dateDialogContract"
                                    width="290px"
                                >
                                    <v-date-picker
                                        ref="pickerDateContract"
                                        v-model="date_contract"
                                        :locale="lang"
                                        @change="dateDialogContract = false"
                                    >
                                    </v-date-picker>
                                </v-dialog>
                                <ValidationProvider
                                    ref="dateContract"
                                    rules="required|date_format"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-text-field
                                        v-model="date_contract"
                                        :error-messages="errors"
                                        :error="!valid"
                                        :disabled="loading"
                                        :label="$t('date_contract')"
                                        prepend-icon="mdi-calendar"
                                        color="primary"
                                        readonly
                                        @click.stop="
                                            dateDialogContract =
                                                !dateDialogContract
                                        "
                                        clearable
                                        @click:clear="date_contract = null"
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider
                                    ref="address_legal"
                                    rules="min:3|max:255"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-text-field
                                        v-model="address_legal"
                                        type="text"
                                        :error-messages="errors"
                                        :error="!valid"
                                        :disabled="loading"
                                        prepend-icon="mdi-map-marker-radius"
                                        :label="$t('address_legal')"
                                        color="primary"
                                        clearable
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider
                                    ref="address_actually"
                                    rules="min:3|max:255"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-text-field
                                        v-model="address_actually"
                                        type="text"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        prepend-icon="mdi-map-marker-radius"
                                        :label="$t('address_actually')"
                                        color="primary"
                                        clearable
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider
                                    ref="clients"
                                    rules=""
                                    v-slot="{ errors, valid }"
                                >
                                    <v-combobox
                                        v-model="clients"
                                        :items="clientItems"
                                        :error="!valid"
                                        :error-messages="errors"
                                        :search-input.sync="clientSearching"
                                        hide-selected
                                        item-text="name"
                                        item-value="id"
                                        :loading="loadingClients"
                                        prepend-icon="mdi-account-supervisor"
                                        :disabled="loading"
                                        :label="$t('company_clients')"
                                        @click:clear="clientItems = []"
                                        @click="clearClients"
                                        color="primary"
                                        multiple
                                        return-object
                                        clearable
                                        autocomplete="off"
                                    >
                                        <template
                                            v-slot:item="{
                                                attrs,
                                                item,
                                                parent,
                                                selected,
                                            }"
                                        >
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                (<span
                                                v-text="
                                                        item.phone
                                                            ? '+' + item.phone
                                                            : ''
                                                    "
                                            ></span
                                            >)
                                            </span>
                                        </template>
                                        <template
                                            v-slot:selection="{
                                                attrs,
                                                item,
                                                parent,
                                                selected,
                                            }"
                                        >
                                            <v-chip
                                                v-if="item === Object(item)"
                                                v-bind="attrs"
                                                :input-value="selected"
                                                :to="{
                                                    name: 'client.edit',
                                                    params: { id: item.id },
                                                }"
                                                @click:close="
                                                    parent.selectItem(item)
                                                "
                                                :color="
                                                    item.active
                                                        ? 'green lighten-5'
                                                        : 'red lighten-5'
                                                "
                                                :title="
                                                    item.active
                                                        ? $t('active')
                                                        : $t('no_active')
                                                "
                                                link
                                                close
                                            >
                                                <span v-html="item.name"></span>
                                            </v-chip>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{
                                                            clientSearching
                                                                ? $t(
                                                                    "nothing_found_by",
                                                                    {
                                                                        search: clientSearching,
                                                                    }
                                                                )
                                                                : $t(
                                                                    "nothing_found_client_name"
                                                                )
                                                        }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                                </ValidationProvider>
                            </v-col>
                            <v-col
                                v-if="can(['administrator', 'manager'])"
                                cols="12"
                            >
                                <ValidationProvider
                                    ref="notes"
                                    rules="min:3|max:65535"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-textarea
                                        v-model="notes"
                                        type="text"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        :label="$t('notes')"
                                        rows="5"
                                        color="primary"
                                        auto-grow
                                        clearable
                                        outlined
                                    >
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="py-0 d-flex justify-start"
                                cols="12"
                                sm="6"
                            >
                                <v-switch
                                    v-model="deleted"
                                    :disabled="loading"
                                    class="ma-0"
                                    :label="
                                        deleted
                                            ? $t('company_deleted')
                                            : $t('company_remove')
                                    "
                                    @change="
                                        deleted ? (active = false) : active
                                    "
                                    color="red"
                                ></v-switch>
                            </v-col>
                            <v-col
                                class="py-0 d-flex justify-start justify-sm-end"
                                cols="12"
                                sm="6"
                            >
                                <v-switch
                                    v-model="active"
                                    :disabled="loading || deleted"
                                    :label="$t('company_active')"
                                    class="ma-0"
                                    color="primary"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-progress-linear
                            v-model="progress"
                            :active="loading"
                            class="mx-2"
                        ></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn
                            type="submit"
                            :disabled="invalid || loading"
                            :loading="loading"
                            color="primary"
                        >
                            {{ $t("save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {mask} from "vue-the-mask";
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";
import MakeLogo from "../components/MakeLogo.vue";

export default {
    name: "CompanyForm",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakeLogo,
    },
    inject: ["forceRerender"],
    data() {
        return {
            heading: null,
            progress: 0,
            tab: 0,
            language: null,
            create: false,
            phoneRaw: null,
            company: null,
            name_legal: null,
            name_director: null,
            name_responsible: null,
            address_actually: null,
            address_legal: null,
            contract_number: null,
            email: null,
            bin_iin: null,
            logo: null,
            notes: null,
            active: false,
            deleted: false,
            loading: false,
            loadingCities: false,
            loadingClients: false,
            loadingCountries: false,
            date_contract: null,
            dateDialogContract: false,
            chain: null,
            country: null,
            countryItems: [],
            countrySearching: null,
            city: null,
            cityItems: [],
            citySearching: null,
            shops: null,

            clients: null,
            clientItems: [],
            clientSearching: null,
            exist_translations: {},
            all_translations: true,
        };
    },
    computed: {
        ...mapGetters(["listLanguages", "lang", "phoneMask"]),
        phone: function () {
            return this.changePhone(this.phoneRaw);
        },
        languages() {
            return this.listLanguages;
        },
        tinymceInit() {
            return this.tinymceInitDefault();
        },
    },
    watch: {
        countrySearching: debounce(function (val) {
            if (val && !this.country) {
                this.getCountries(val);
            }
        }, 500),
        citySearching: debounce(function (val) {
            if (val && !this.city) {
                this.getCities(val);
            }
        }, 500),
        clientSearching: debounce(function (val) {
            if (val) {
                this.getClients(val);
            }
        }, 500),
    },
    async mounted() {
        this.language = this.languages[this.tab];
        await this.checkCreate();
    },
    methods: {
        async setLanguage(val) {
            this.language = this.languages[val];
            await this.checkCreate();
        },
        clearEmptyClientItems(items) {
            if (items && items.length > 0) {
                this.clients = items.filter(
                    (item) => typeof item === "object"
                );
            }
        },
        clearCountries() {
            if (!this.country) {
                this.countryItems = [];
                this.city = null;
                this.cityItems = [];
            }
        },
        clearCities() {
            if (!this.city) {
                this.cityItems = [];
            }
        },
        clearClients() {
            if (!this.clients) {
                this.clientItems = [];
            }
        },
        checkCreate() {
            if (this.$route.name === "company.create") {
                this.create = true;
                this.heading = this.$t("company_creation");
            } else {
                this.heading = this.$t("company_editing");
                if (this.$route.params.id) {
                    this.getCompany(this.$route.params.id);
                }
            }
        },
        async getClients(str) {
            if (str) {
                this.loadingClients = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.client = str;
                }
                await this.$http
                    .get("admin/client", {
                        params: params,
                    })
                    .then((res) => {
                        this.clientItems = res.body.data;
                    })
                    .catch((err) => {
                        this.clientItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_clients")
                        );
                    })
                    .finally((end) => {
                        this.loadingClients = false;
                    });
            }
        },
        async getCountries(str) {
            if (str) {
                this.loadingCountries = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.country = str;
                }
                await this.$http
                    .get("admin/country", {
                        params: params,
                    })
                    .then((res) => {
                        this.countryItems = res.body.data;
                    })
                    .catch((err) => {
                        this.countryItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_countries")
                        );
                    })
                    .finally((end) => {
                        this.loadingCountries = false;
                    });
            }
        },
        async getCities(str) {
            if (str) {
                this.loadingCities = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.city = str;
                }
                await this.$http
                    .get("admin/city", {
                        params: params,
                    })
                    .then((res) => {
                        this.cityItems = res.body.data;
                    })
                    .catch((err) => {
                        this.cityItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_cities")
                        );
                    })
                    .finally((end) => {
                        this.loadingCities = false;
                    });
            }
        },
        async getCompany(id) {
            var _this = this;
            this.progress = 0;
            this.loading = true;
            this.id = id;
            let params = {};
            if (this.language) {
                params.language = this.language;
            }
            await this.$http
                .get(`admin/company/${id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            );
                        }
                    },
                })
                .then((res) => {
                    this.company = res.body.data.name;
                    this.name_legal = res.body.data.name_legal;
                    this.name_director = res.body.data.name_director;
                    this.name_responsible = res.body.data.name_responsible;
                    this.address_actually = res.body.data.address_actually;
                    this.address_legal = res.body.data.address_legal;
                    this.contract_number = res.body.data.contract_number;
                    this.phoneRaw = res.body.data.phone;
                    this.bin_iin = res.body.data.bin_iin;
                    this.email = res.body.data.email;
                    this.date_contract = res.body.data.date_contract;
                    this.active = res.body.data.active;
                    this.deleted = res.body.data.deleted;
                    this.notes = res.body.data.notes;
                    this.logo = res.body.data.logo;
                    this.clients = res.body.data.clients;
                    this.exist_translations = res.body.data.exist_translations;
                    this.all_translations = res.body.data.all_translations;

                    let country = res.body.data.country;
                    if (country && country.id) {
                        this.country = country.id;
                        this.countryItems = [res.body.data.country];
                    } else {
                        this.countryItems = [];
                    }

                    let city = res.body.data.city;
                    if (city && city.id) {
                        this.city = city.id;
                        this.cityItems = [res.body.data.city];
                    } else {
                        this.cityItems = [];
                    }
                })
                .catch((err) => {
                    this.$toastr.error(this.$t("failed_to_get_company"));
                })
                .finally((end) => {
                    this.progress = 0;
                    this.loading = false;
                });
        },
        async saveCompany() {
            var _this = this;
            this.progress = 0;
            this.loading = true;
            var formData = new FormData();
            if (this.language) {
                formData.append("language", this.language);
            }
            if (this.company) {
                formData.append("company", this.company);
            }
            if (this.name_legal) {
                formData.append("name_legal", this.name_legal);
            }
            if (this.name_director) {
                formData.append("name_director", this.name_director);
            }
            if (this.name_responsible) {
                formData.append("name_responsible", this.name_responsible);
            }
            if (this.phone) {
                formData.append("phone", this.phone);
            }
            if (this.email) {
                formData.append("email", this.email);
            }
            if (this.bin_iin) {
                formData.append("bin_iin", this.bin_iin);
            }
            if (this.address_actually) {
                formData.append("address_actually", this.address_actually);
            }
            if (this.address_legal) {
                formData.append("address_legal", this.address_legal);
            }
            if (this.contract_number) {
                formData.append("contract_number", this.contract_number);
            }
            if (this.date_contract) {
                formData.append("date_contract", this.date_contract);
            }
            if (this.notes) {
                formData.append("notes", this.notes);
            }
            if (this.active) {
                formData.append("active", 1);
            }
            if (this.deleted) {
                formData.append("deleted", 1);
            }
            if (this.country) {
                if (this.country.id) {
                    formData.append("country", this.country.id);
                } else {
                    formData.append("country", this.country);
                }
            }
            if (this.city) {
                if (this.city.id) {
                    formData.append("city", this.city.id);
                } else {
                    formData.append("city", this.city);
                }
            }
            if (this.clients && this.clients.length > 0) {
                for (let i in this.clients) {
                    if (
                        this.clients[i].id !== undefined &&
                        this.clients[i].id > 0
                    ) {
                        formData.append(`clients[${i}]`, this.clients[i].id);
                    }
                }
            }

            if (this.logo) {
                if (this.logo.length > 250) {
                    var mimeType = this.getMimeType(this.logo);
                    var blob = this.dataURL64toBlob(this.logo);
                    if (mimeType && blob) {
                        formData.append("logo", blob, mimeType);
                    }
                } else {
                    formData.append("logo", this.logo);
                }
            }

            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/company/${this.$route.params.id}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round(
                                    (e.loaded / e.total) * 100
                                );
                            }
                        },
                    })
                    .then((res) => {
                        this.$toastr.success(
                            this.$t("company_has_been_updated")
                        );
                        if (
                            res &&
                            res.body &&
                            res.body.data &&
                            res.body.data.exist_translations
                        ) {
                            this.exist_translations =
                                res.body.data.exist_translations;
                            this.all_translations =
                                res.body.data.all_translations;
                        }
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("company_has_not_been_updated")
                        );
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ]);
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message);
                            }
                        }
                    })
                    .finally((end) => {
                        this.progress = 0;
                        this.loading = false;
                    });
            } else {
                // Add
                await this.$http
                    .post("admin/company", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round(
                                    (e.loaded / e.total) * 100
                                );
                            }
                        },
                    })
                    .then((res) => {
                        this.$toastr.success(this.$t("company_has_been_added"));
                        if (
                            res &&
                            res.body &&
                            res.body.data &&
                            res.body.data.id
                        ) {
                            this.exist_translations =
                                res.body.data.exist_translations;
                            this.all_translations =
                                res.body.data.all_translations;
                            this.$router.push({
                                name: "company.edit",
                                params: {
                                    id: res.body.data.id,
                                },
                            });
                        } else {
                            this.$router.push({
                                name: "company",
                            });
                        }
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("company_has_not_been_added")
                        );
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ]);
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message);
                            }
                        }
                    })
                    .finally((end) => {
                        this.progress = 0;
                        this.loading = false;
                    });
            }
        },
    },
};
</script>
